// src/components/Hero.tsx

import React from 'react';
import { ReactComponent as Logo } from '../images/SVG/DC_logo.svg';
import { ReactComponent as AppStore } from '../images/SVG/apple.svg';

import '../styles/Hero.scss';

const Hero: React.FC = () => {
  return (
    <div className="hero">
      <Logo className="hero-logo" />
      <p className="hero-description">
        Trading Card Game in Southern Gothic Setting
      </p>

      <div className="hero-available-on">
        <span>Available on:</span>
      </div>
     
      <div className="hero-buttons">
        <AppStore />
        <a className="google_play" href="https://play.google.com" aria-label="Get it on Google Play">
        </a>
      </div>
    </div>
  );
};

export default Hero;
